import { Component, OnInit, OnDestroy, Output } from '@angular/core';
import { OrganizationService } from '../api-client/organization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UiService } from '../ui.service';
import { EventEmitter } from 'events';
import { DepartmentService } from '../api-client/department.service';
import { DisciplineService } from '../api-client/discipline.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../api-client/utility.service';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit, OnDestroy {

  department: any;
  disciplines: any;
  employees: any;
  newItems: any;
  discpline:any;

  private _paramDepartmentId: any;
  private _organizationServiceSubscription: Subscription;
  private _paramsChangeSubscriber: Subscription;
  private _assignEmployeeDepartmentSubscription: Subscription;
  private _assignEmployeeDiscplineSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _uiService: UiService,
    private _toster: ToastrService,
    private _organizationService: OrganizationService,
    private _disciplineService: DisciplineService,
    private _utilityService: UtilityService,
    private _departmentService: DepartmentService
  ) { }

  ngOnInit() {
    this._paramsChangeSubscriber = this._route.params.subscribe(params => {
      this._paramDepartmentId = params.id;
      this.refreshDepartmentInfo();
    });

    this._organizationServiceSubscription = this._organizationService.onDepartmentsUpdated.subscribe(_ => {
      this.refreshDepartmentInfo();
    });
    this._assignEmployeeDepartmentSubscription = this._uiService.assignEmployeeInDepartmentDiscpline.subscribe((employee: any) => {
      if(this.discpline.id){
        this.assignEmployeeInDiscpline(employee,'link');
      }else{
        this.assignEmployeeInDepartment(employee,'link');
      }
    });
    this._utilityService.isDataUpdated.subscribe((data) => {
      if(data.data){
        this.getDepartmentWiseEmployees();
      }
    });
  }

  private refreshDepartmentInfo() {
    this._uiService.setDepartmentId(this._paramDepartmentId);
    this.department = this._organizationService.getDepartment(this._paramDepartmentId);
    if (this.department != null) {
      this._uiService.changeAppTitle.next('Department of ' + this.department.name);
    } else {
      this._uiService.changeAppTitle.next('Unknown Department');
    }
    this._uiService.detailOutletUpdateForId.next(this._paramDepartmentId);
    this._departmentService.getDisciplines(this._paramDepartmentId)
      .subscribe(disciplines => this.disciplines = disciplines);
    this.getDepartmentWiseEmployees();
  }

  ngOnDestroy(): void {
    this._paramsChangeSubscriber.unsubscribe();
    this._organizationServiceSubscription.unsubscribe();
    this._assignEmployeeDepartmentSubscription.unsubscribe();
  }

  disciplineDetails(discipline: any) {
    this._router.navigate([{
      outlets: { rightMainPopup: ['discipline-details', discipline.id] }
    }]);
  }

  employeeDetails(employee: any) {
    //this._router.navigate(["paper/" + paper.id]);
    this._router.navigate([{
      outlets: { rightMainPopup: ['staff-details', employee.id] }
    }]);
  }

  searchEmployee() {
    this._router.navigate([{
      outlets: { rightMainPopup: ['search-employee'] }
    }]);
  }

  setEmployees(employees: any) {
    this._uiService.setDepartmentWiseEmployee(employees.map((result: any) => {
      return result;
    }));
  }

  getDisciplineWiseEmployees(discipline: any) {
    this.discpline = discipline;
    this.employees = [];
    this._disciplineService.getEmployees(discipline.id).subscribe((result:any)=>{
      this.employees = result;
      this.setEmployees(result);
    })
  }

  getDepartmentWiseEmployees() {
    this.discpline = "";
    this.employees = [];
    this._departmentService.getEmployees(this._paramDepartmentId)
      .subscribe((employees:any) => {        
        employees.sort((x: any, y: any) => {
          return y.headOfDepartment - x.headOfDepartment;
        });
        this.employees = employees;
        this.setEmployees(employees);
      });
  }

  assignEmployeeInDiscpline(employee:any,type:any){
    var data = {employeeId:employee.id,disciplineId:this.discpline.id};
    this._disciplineService.linkUnlinkEmployee(data,type).subscribe(
      (response: any) => {
        if(type=='link'){
          this._toster.success("Employee linked");
        }else{
          this._toster.success("Employee Unlinked");
        }
        this.getDisciplineWiseEmployees(this.discpline);
        return false;
      },
      error => {
        this._toster.error(error.message);
      }
    )
  }

  assignEmployeeInDepartment(employee:any,type:any){
    var data = {employeeId:employee.id,departmentId:this._paramDepartmentId};
    this._departmentService.linkUnlinkEmployee(data,type).subscribe(
      (response: any) => {
        if(type=='link'){
          this._toster.success("Employee linked");
        }else{
          this._toster.success("Employee Unlinked");
        }        
        this.getDepartmentWiseEmployees();
        return false;
      },
      error => {
        this._toster.error(error.message);
      }
    )
  }

  unlinkEmployee(employee:any,type:any){
    if(this.discpline.id){
      this.assignEmployeeInDiscpline(employee,type);
    }else{
      this.assignEmployeeInDepartment(employee,type);
    }
  }

  markAsHod(employee:any){
    var data = {employeeId:employee.id,departmentId:this._paramDepartmentId};
    this._departmentService.markeAsHod(data).subscribe((response:any)=>{
      this._toster.success("HOD Assigned");
      this.getDepartmentWiseEmployees();
    },
    error => {
      this._toster.error(error.message);
    })
  }

}
