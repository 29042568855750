<div class="student_details_section d-flex">
    <div class="left_bg w-25 p-4">
        <h5>{{ 'Filters' | translate }} :</h5>
        <div class="row-filter mb-3">
            <input [(ngModel)]="filterKeyword" class="form-control" type="text" placeholder="Name">
        </div>        
        <div class="text-right">
            <button (click)="staffFilter()" class="btn btn-primary font-weight-500 text-80 p-1 pr-2 pl-2">{{ 'Apply' | translate }} <em
                    aria-hidden="true" class="fa fa-arrow-right"></em></button>
        </div>

    </div>
    <div class="p-4 w-75">        
        <div class="d-flex justify-content-between align-items-center">
            <!-- <h4 class="m-0">Students</h4> -->
            <div class="reply_cont d-flex justify-content-between align-items-center">                
                <div class="form-group m-0 mr-2 d-flex justify-content-between align-items-center font-weight-500">
                    <em class="fa fa-upload position-relative ml-2 p-2 rounded-circle">
                        <input type="file" (change)="uploadFile($event.target)" class="hidden-file" id="importStudents">                    
                    </em>                
                    <label for="importStudents" class="m-0">{{ 'Import' | translate }}</label>
                </div> 
                <a class="ml-2 mr-2 font-weight-500" (click)="exportCsv()"><em class="fa fa-download p-2 rounded-circle"></em> <span>{{ 'Export' | translate }}</span></a>               
                <a class="ml-2 mr-2 font-weight-500" href="#"><em class="fa fa-unlock p-2 rounded-circle" aria-hidden="true"></em> <span>{{ 'Block/Unblock' | translate }}</span></a>                
                <a class="ml-2 mr-2 font-weight-500" (click)="redirectTo({id:0})"><em class="fa fa-plus p-2 rounded-circle"></em> <span>{{ 'Add' | translate }}</span></a>
                <a class="ml-2 font-weight-500" (click)="relode()"><em class="fa fa-refresh p-2 rounded-circle" aria-hidden="true"></em></a>
            </div>
        </div>
        <div class="text-80">
            <div class="p-1 pr-2 pl-2 text-100 font-weight-500">
                <div class="row white p-2">
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500">{{ 'Name' | translate }}</p>
                    </div>
                    <div class="col-sm-2 d-flex align-items-start">
                        <p class="m-0 font-weight-500">{{ 'Email' | translate }}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500">{{ 'Mobile' | translate }}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500">{{ 'Designation' | translate }}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500">{{ 'DOJ' | translate }}</p>
                    </div>                  
                    
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500 text-center">{{ 'Action' | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="p-1 pr-2 pl-2">
                <div *ngFor="let student of staffs | paginate: {id: 'studentsPagination',
                                                          itemsPerPage: pageSize,
                                                          currentPage: papersCurrentPage,
                                                          totalItems: totalItemsCount}; index as i;"
                    (click)="redirectTo(student)" class="row item-row p-2">

                    <div class="col-sm-2 d-flex align-items-start">
                        <p class="m-0 font-weight-400">{{student.name}}</p>
                    </div>
                    <div class="col-sm-3 d-flex align-items-start">
                        <p class="m-0 font-weight-400">{{student.email}}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-400">{{student.mobile}}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-400">{{student.designation}}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-400">{{student.dateOfJoin | date:'dd-MMM-yyyy'}}</p>
                    </div>
                    <div class="col-sm-1 text-center">
                        <em (click)="redirectTo(student)" class="fa fa-pencil mt-3px"></em>
                    </div>
                </div>
            </div>
            <div class="d-flex p-0 pt-3 justify-content-between">
                <div class="font-weight-600">Total {{ totalItemsCount }} in {{ totalPageCount }} pages.</div>
                <pagination-controls id="studentsPagination" (pageChange)="onPageChange($event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>