import { Component, OnInit } from '@angular/core';
import { AcademiaService } from '../api-client/academia.service';
import { Subscription } from 'rxjs';
import { config } from '../config';
import { UiService } from '../ui.service';
import { ExamService } from '../api-client/exam.service';
import { count } from 'rxjs/operators';
import { DisciplineService } from '../api-client/discipline.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../api-client/utility.service';
import { OrganizationService } from '../api-client/organization.service';
import { ExportAsService } from 'ngx-export-as';
declare var $: any;

@Component({
  selector: 'app-hod-wise-discipline',
  templateUrl: './hod-wise-discipline.component.html',
  styleUrls: ['./hod-wise-discipline.component.css']
})
export class HodWiseDisciplineComponent implements OnInit {

  disciplines: any;
  academiaSessions: any;
  papers: any;
  env = config;
  disciplineDetails: any;
  totalCount: number = 0;
  disciplineSearchSettings = {
    singleSelection: false,
    text: "Select Discipline",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    badgeShowLimit: 3
  };
  filterKeyword = "";
  filterDiscipline = [];
  filterSemester = 0;
  filterLevel: any = 0;
  filterSession = "";
  filters = {};
  activeSession: any;
  activeExam: any;
  showSemesters = [];
  examinationId: any;
  employees: any;
  examinerHeader = config.examinerHeader;
  params = {
    "freeText": "",
    "startIndex": 0,
    "pageSize": 0
  };
  exportHeading: any;

  private _academiaServiceSubscription: Subscription;
  paperDetails: any;
  constructor(
    private _academiaService: AcademiaService,
    private _disciplineService: DisciplineService,
    private _router: Router,
    private _tosterService: ToastrService,
    private _utilityService: UtilityService,
    private _organizationService: OrganizationService,
    private _exportAsService: ExportAsService,
    private _uiService: UiService,
  ) {
    this._academiaServiceSubscription = _academiaService.onDisciplinesUpdated.subscribe(_ => {
      this.academiaSessions = this._academiaService.getAcademicSession();
      this.activeSession = this._academiaService.getActiveAcademicSession();
      this.disciplines = this._academiaService.getDisciplines();
      this.filterSession = this.activeSession.id;
    });

    this._organizationService.employees(this.params).subscribe((result: any) => {
      this.employees = result.items;
    })
  }

  async ngOnInit() {
    this.disciplines = this._academiaService.getDisciplines();
    this._uiService.changeAppTitle.next("Disciplines");
    await this._academiaService.getActiveExam().toPromise().then((result: any) => {
      this.activeExam = result;
      this.showSemesters = [];
      for (var i = 0; i < result.programs.length; i++) {
        for (var s = 0; s < result.programs[i].semesters.length; s++) {
          if (!this.showSemesters.includes(result.programs[i].semesters[s].semester)) {
            this.showSemesters.push(result.programs[i].semesters[s].semester);
          }
        }
      }
    });
    this.academiaSessions = this._academiaService.getAcademicSession();
    this.activeSession = this._academiaService.getActiveAcademicSession();

    if (this.activeSession) {
      this.filterSession = this.activeSession.id;
    }

  }

  paperFilter() {

    if (this.filterDiscipline.length == 0) {
      this._tosterService.error("Please select discipline.");
      return false;
    }

    var level = [];
    if (this.filterLevel && this.filterLevel != 0) {
      level.push(Number(this.filterLevel));
    }
    var data = {
      "freeText": this.filterKeyword,
      "academicSessionId": this.filterSession,
      "disciplineIds": this.filterDiscipline.map(x => x.id),
      "semester": this.filterSemester,
      "levels": level,
      "startIndex": 0,
      "pageSize": 0
    };

    var heading = "";
    var session = this.academiaSessions.filter((x: any) => x.id == this.filterSession);
    if (this.filterLevel) {
      heading += `${this.env.academicLevel[this.filterLevel]} `;
    }
    if (this.filterDiscipline && this.filterDiscipline[0]) {
      heading += `${this.filterDiscipline[0].name} `;
    }
    if (session.length > 0) {
      heading += `${session[0].name} `;
    }
    if (this.filterSemester) {
      heading += `semester ${this.filterSemester}`;
    }

    this.exportHeading = heading;

    this._academiaService.papers(data).subscribe((result: any) => {
      this.papers = {};
      this.totalCount = result.totalCount;
      var result = result.items;
      for (var i = 0; i < result.length; i++) {
        if (!this.papers[result[i].semester] && this.showSemesters.includes(result[i].semester)) {
          this.papers[result[i].semester] = [];
        }
        if (this.papers[result[i].semester]) {
          this.papers[result[i].semester].push(result[i]);
        }
      }
      //console.log(this.papers);
      /* for (var i = 0; i < result.items.length; i++) {
        result.items[i].sessionName = this._academiaService.getSessionDetails(result.items[i].academicSessionId).name;
      } */
      //this.papers = result.items;

    })
  }

  unsetEvalutors(paper: any) {
    this.paperDetails = paper;
    $('#DeleteTask').modal('toggle');
    var examinationId = paper.evaluators[0].examinationId;
    paper['examinationId'] = examinationId;
    // this._utilityService.unsetEvalutors(paper).subscribe((result:any)=>{
    //   this._tosterService.success("Evalutors unset");
    //   return false;
    // })
  }
  unset() {
    var examinationId = this.paperDetails.evaluators[0].examinationId;
    this.paperDetails['examinationId'] = examinationId;
    this._utilityService.unsetEvalutors(this.paperDetails).subscribe((result: any) => {
      this._tosterService.success("Evalutors unset");
      return false;
    })
  }

  /* paperConfigure(paper: any) {
    this._router.navigate(["paper/"+paper.id]);
    return false;
  } */

  previewCsvData(fieldMaps: any, results: any) {
    this._uiService.indeterminateProgressTextSet.next('Arrange data...');
    var columns = [];
    var columnsWidth = {};
    var columnSources = [];

    /* columnSources['paperType'] = Object.keys(config.paperType).map((key) => {
      return {id:Number(key),name:config.paperType[key]};
    });
    
    columnSources['level'] = Object.keys(config.academicLevel).map((key) => {
      return {id:Number(key),name:config.academicLevel[key]};
    });
    
    columnSources['secondaryPaperType'] = Object.keys(config.secondaryPaperType).map((key) => {
      return {id:Number(key),name:config.secondaryPaperType[key]};
    }); */

    columnSources['academicSessionId'] = this._academiaService.getAcademicSession().map((x: any) => {
      return { id: x.id, name: x.name };
    });

    /* columnSources['disciplineId'] = this._academiaService.getDisciplines().map((x:any)=>{
      return {id:x.id, name:x.name};
    }); */

    columnSources['examinerId'] = this.employees.map((x: any) => {
      return { id: x.id, name: x.email };
    });

    columnSources['examinationId'] = [{ id: this.activeExam.id, name: this.activeExam.name }];
    columnSources['mode'] = [{ id: 2, name: "Examiner" }, { id: 4, name: "Reviewer" }];

    var arrangedData = results.map((value: any) => {
      var item = {};
      for (var key in fieldMaps) {
        var valueWidth = (value[fieldMaps[key]] + '').length * 15;
        item[key] = value[fieldMaps[key]];
        if (!columnsWidth[key] || columnsWidth[key] < valueWidth) {
          columnsWidth[key] = valueWidth;
        }
      }

      /* if(item['level'] && item['level'].length==2){
        var levelName = Object.values(config.academicLevelShort);
        var levelKey = Object.keys(config.academicLevelShort);
        if(levelKey.indexOf(item['level'])>-1){
          item['level'] = levelName[levelKey.indexOf(item['level'])];
        }
      } */

      if (!item['examinationId']) {
        item['examinationId'] = this.activeExam.id;
      }
      return item;
    });

    this._uiService.replaceCsvValueWithId(columnSources, arrangedData);
    this._uiService.createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources);
    columnsWidth = null;
    this._uiService.indeterminateProgressTextSet.next(null);

    this._uiService.openDataPreviewWindow(arrangedData, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(Object.keys(fieldMaps), arrangedData, columns);
        }
      }
    ]);
  }

  onImportPreviewCompleted(fields: any, arrangedData: any, columns: any) {
    var data = this._uiService.getPreviewModifiedData();
    var converted = data.map((value: any) => {
      var item = {};
      for (var i = 0; i < fields.length; i++) {
        item[fields[i]] = value[i];
      }
      return item;
    });
    this._uiService.closeDataPreview();
    var worker = new Worker('../workers/import-examiners.worker', { name: 'examiner-paper-worker', type: 'module' });

    this._uiService.startWorker(worker, { values: converted, sessions: this.academiaSessions, token: sessionStorage.getItem("token") })
      .then(() => {
        //show success message, if required
      }).catch(e => {
        if (e.validation) {
          this.onImportValidationError(e.validation, converted, columns, fields);
        }
      });
  }

  onImportValidationError(validation: any, converted: any, columns: any, fields: any) {
    this._uiService.onDataPreviewReady = () => {
      var allFields = Object.keys(converted[0]);
      for (var i = 0; i < validation.length; i++) {
        var commonErrors = [];
        for (var field in validation[i]) {
          var fieldIndex = allFields.indexOf(field);
          if (fieldIndex <= 0) {
            commonErrors = commonErrors.concat(validation[i][field]);
          } else {
            this._uiService.setPreviewDataCellCommentAndStyle(i + 1, fieldIndex + 1, validation[i][field].join('\n'), null);
          }
        }
        if (commonErrors.length > 0) {
          this._uiService.setPreviewDataCellCommentAndStyle(i + 1, 1, commonErrors.join('\n'), null);
        }
      }
    };
    this._uiService.openDataPreviewWindow(converted, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(fields, converted, columns);
        }
      }
    ]);
  }

  mapCsvField(results: any) {
    this._uiService
      .mapCsvField(results.meta.fields, this.examinerHeader)
      .then((maps: any) => {
        this.previewCsvData(maps, results.data);
      }).catch(() => { });
  }

  uploadFile(fileEle: any) {
    this._uiService
      .parseCsv(fileEle.files[0])
      .then((results: any) => {
        fileEle.value = null;
        this.mapCsvField(results);
      }).catch(() => {
        fileEle.value = null;
      });
  }

  export(type: any, elementId: any) {
    var configs = { type: type, elementId: elementId };
    setTimeout(() => {
      this._exportAsService.save(configs, this.exportHeading).subscribe(() => {
      });
    }, 1000);
  }

}
