<div class="student_details_section d-flex">
    <div class="left_bg w-25 p-3">
        <h5>{{ 'Filters' | translate }} :</h5>
        <div class="row-filter mb-3">
            <input [(ngModel)]="filterKeyword" class="form-control" type="text" placeholder="Paper Name, Paper Code">
        </div>
        <div class="row-filter mb-3">
            <select [(ngModel)]="filterSession" class="form-control">
                <option value="">{{ 'Select session' | translate }}</option>
                <option *ngFor="let session of academiaSessions" [value]="session.id">{{session.name}}</option>
            </select>
        </div>
        <div class="row-filter mb-3">
            <select [(ngModel)]="filterSemester" class="form-control">
                <option value="0">{{ 'Select semester' | translate }}</option>
                <option *ngFor="let semester of showSemesters" [value]="semester">{{ 'Semester' | translate }}
                    {{semester}}</option>
            </select>
        </div>
        <div class="row-filter mb-3">
            <select [(ngModel)]="filterLevel" class="form-control">
                <option value="0">{{ 'Academic level' | translate }}</option>
                <option *ngFor="let academiclevel of env.academicLevel | keyvalue" [value]="academiclevel.key">
                    {{academiclevel.value}}</option>
            </select>
        </div>
        <div class="row-filter mb-3">
            <angular2-multiselect class="form-control" [data]="disciplines" [settings]="disciplineSearchSettings"
                [(ngModel)]="filterDiscipline">
                <c-item>
                    <ng-template let-item="item">
                        {{item.name}}
                    </ng-template>
                </c-item>
                <c-badge>
                    <ng-template let-item="item">
                        {{item.name}}
                    </ng-template>
                </c-badge>
            </angular2-multiselect>
        </div>
        <div class="text-right">
            <button (click)="paperFilter()"
                class="btn btn-primary font-weight-500 text-80 p-1 pr-2 pl-2">{{ 'Apply' | translate }} <em
                    aria-hidden="true" class="fa fa-arrow-right"></em></button>
        </div>

    </div>
    <div class="p-3 w-75">
        <ng-template #noResult>No record found</ng-template>
        <!-- <div class="d-flex p-1">
            <div class="form-group m-0 mr-2 d-flex justify-content-between align-items-center font-weight-500">
                <em class="fa fa-upload position-relative ml-2 p-2 rounded-circle">
                    <input type="file" (change)="uploadFile($event.target)" class="hidden-file" id="importPapers">
                </em>
                <label for="importPapers" class="m-0">{{ 'Import Examiner' | translate }}</label>
            </div>
        </div>
        <div *ngIf="totalCount>0" class="d-flex justify-content-end">
            <div class="reply_cont d-flex align-items-ceter"><a (click)="export('csv','examinerPaper')"
                    class="ml-2 mr-2 font-weight-500"><em
                        class="fa fa-file-excel-o p-2 rounded-circle"></em><span>Export CSV</span></a><a
                    (click)="export('pdf','examinerPaperPdf')" class="ml-2 mr-2 font-weight-500"><em aria-hidden="true"
                        class="fa fa-file-pdf-o p-2 rounded-circle"></em><span>Export PDF</span></a></div>
        </div> -->
        <div id="examinerPaperPdf">
            <div *ngIf="totalCount>0;else noResult" class="d-flex p-1">
                <div class="col-sm-2">
                    <p class="m-0 h5 pt-1 pb-1 font-weight-500">{{ 'Group' | translate }}</p>
                </div>
                <div class="col-sm-2">
                    <p class="m-0 h5 pt-1 pb-1 font-weight-500">{{ 'Code' | translate }}</p>
                </div>
                <div class="col-sm-5">
                    <p class="m-0 h5 pt-1 pb-1 font-weight-500">{{ 'Name' | translate }}</p>
                </div>
                <div class="col-sm-1">
                    <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center">{{ 'Credit' | translate }}</p>
                </div>
                <div class="col-sm-2">
                    <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center">{{ 'Full Marks' | translate }}</p>
                </div>
            </div>
            <div *ngFor="let semester of papers|keyvalue">
                <p class="m-0 h6 p-1 pl-3 pr-3"><strong>{{ 'Semester' | translate }} : {{semester.key}}</strong></p>
                <ng-container *ngFor="let paper of semester.value">
                    <div class="d-flex item-row p-1" (click)="paperConfigure(paper)">
                        <div class="col-sm-2">
                            <p class="m-0 h6 pt-1 pb-1">
                                <span *ngIf="paper.evaluators.length>0">
                                    <a (click)="unsetEvalutors(paper)"><i
                                            class="fa fa-check-circle text-success text-22"></i></a>
                                </span>
                                <span *ngIf="paper.evaluators.length==0" style="cursor: auto;"><i style="cursor: auto;" class="fa fa-circle-thin text-muted"></i>
                                </span> {{paper.groupCode}}</p>
                        </div>
                        <div class="col-sm-2">
                            <p class="m-0 h6  pt-1 pb-1">{{paper.code}}</p>
                        </div>
                        <div class="col-sm-5">
                            <p class="m-0 h6 pt-1 pb-1"> {{paper.name}}</p>
                        </div>
                        <div class="col-sm-1">
                            <p class="m-0 h6 pt-1 pb-1 text-center">{{paper.credit}}</p>
                        </div>
                        <div class="col-sm-2">
                            <p class="m-0 h6 pt-1 pb-1 text-center">{{paper.fullMarks}}</p>
                        </div>
                    </div>
                    <div *ngIf="paper.evaluators.length>0"
                        class="d-flex justify-content-between p-1 text-80 white border">
                        <div class="p-2 w-50">
                            <div class="h5 m-0 p-2 mb-1 grey lighten-3 d-flex justify-content-between">
                                <span>Examiners</span>
                                <span>End Term</span>
                            </div>
                            <ng-container *ngFor="let evaluator of paper.evaluators">
                                <div *ngIf="evaluator.type==2" class="p-1 d-flex justify-content-between">
                                    <span><i *ngIf="evaluator.allowSubmitMidTermMarks" class="fa fa-check"
                                            aria-hidden="true"></i> {{evaluator.examinerName}}</span>
                                    <span>{{evaluator.alocatedEndTermMarks}}</span>
                                </div>
                            </ng-container>
                        </div>
                        <div class="p-2 w-50">
                            <div class="h5 m-0 p-2 mb-1 grey lighten-3  d-flex justify-content-between">
                                <span>Reviewers</span>
                                <span>End Term</span>
                            </div>
                            <ng-container *ngFor="let evaluator of paper.evaluators">
                                <div *ngIf="evaluator.type==1" class="p-1 d-flex justify-content-between">
                                    <span><i *ngIf="evaluator.allowSubmitMidTermMarks" class="fa fa-check"
                                            aria-hidden="true"></i>{{evaluator.examinerName}}</span>
                                    <span>{{evaluator.alocatedEndTermMarks}}</span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <table class="d-none" id="examinerPaper">
            <tr>
                <td>{{exportHeading}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th>Group</th>
                <th>Code</th>
                <th>Name</th>
                <th>Credit</th>
                <th>Full Marks</th>
            </tr>
            <ng-container *ngFor="let semester of papers|keyvalue">
                <tr>
                    <td>{{ 'Semester' | translate }} : {{semester.key}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <ng-container *ngFor="let paper of semester.value">
                    <tr>
                        <td>{{paper.groupCode}}</td>
                        <td>{{paper.code}}</td>
                        <td>{{paper.name}}</td>
                        <td>{{paper.credit}}</td>
                        <td>{{paper.fullMarks}}</td>
                    </tr>
                    <ng-container *ngIf="paper.evaluators.length>0">
                        <tr>
                            <td>Type</td>
                            <td>Name</td>
                            <td>IA</td>
                            <td></td>
                            <td>End Term</td>
                        </tr>
                        <ng-container *ngFor="let evaluator of paper.evaluators">
                            <tr>
                                <td>{{env.evaluatorType[evaluator.type]}}</td>
                                <td>{{evaluator.examinerName}}</td>
                                <td>{{evaluator.allowSubmitMidTermMarks}}</td>
                                <td></td>
                                <td>{{evaluator.alocatedEndTermMarks}}</td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </ng-container>
                </ng-container>
            </ng-container>
        </table>
    </div>

    <div class="modal fade" id="DeleteTask" tabindex="-1" role="dialog" aria-labelledby="DeleteTaskModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="DeleteTaskModalLabel">Unset</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <h2>Are you sure</h2>
                    <p>Do you want to Evalutors unset ?</p>
                    <button type="button" data-dismiss="modal"
                        class="btn btn-sm btn-outline-dark mr-4">Cancel</button>
                    <button type="button" class="btn btn-sm btn-success del-btn mr-4"
                        (click)="unset()">Unset</button>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
