import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { LoginComponent } from './login/login.component';
import { OrganizationComponent } from './organization/organization.component';
import { DepartmentComponent } from './department/department.component';
import { DepartmentUsersComponent } from './department-users/department-users.component';
import { BlankComponent } from './blank/blank.component';
import { ProgramComponent } from './program/program.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { StudentSummaryComponent } from './student-summary/student-summary.component';
import { PaperSummaryComponent } from './paper-summary/paper-summary.component';
import { StudentComponent } from './student/student.component';
import { PaperComponent } from './paper/paper.component';
import { ExaminersComponent } from './examiners/examiners.component';
import { ReviewersComponent } from './reviewers/reviewers.component';
import { StudentsListComponent } from './students-list/students-list.component';
import { SessionsComponent } from './sessions/sessions.component';
import { ExamsComponent } from './exams/exams.component';
import { PapersComponent } from './papers/papers.component';
import { StudentsComponent } from './students/students.component';
import { AuthGuardService } from './_guards/auth-guard.service';
import { LoginGuardService } from './_guards/login-guard.service';
import { SessionDetailsComponent } from './session-details/session-details.component';
import { DisciplineDetailsComponent } from './discipline-details/discipline-details.component';
import { ProgramDetailsComponent } from './program-details/program-details.component';
import { OrganizationDetailsComponent } from './organization-details/organization-details.component';
import { StaffDetailsComponent } from './staff-details/staff-details.component';
import { DataImportMapperComponent } from './data-import-mapper/data-import-mapper.component';
import { DataPreviewComponent } from './data-preview/data-preview.component';
import { SearchEmployeeComponent } from './search-employee/search-employee.component';
import { ExaminerAllocationStatusComponent } from './examiner-allocation-status/examiner-allocation-status.component';
import { MarksSubmissionStatusComponent } from './marks-submission-status/marks-submission-status.component';
import { LoggedInUserStatusComponent } from './logged-in-user-status/logged-in-user-status.component';
import { InviteExaminerAllocationComponent } from './invite-examiner-allocation/invite-examiner-allocation.component';
import { NotificationTemplateComponent } from './notification-template/notification-template.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivilegesComponent } from './privileges/privileges.component';
import { HodWiseDisciplineComponent } from './hod-wise-discipline/hod-wise-discipline.component';
import { StaffsComponent } from './staffs/staffs.component';
import { PaperStudentMapComponent } from './paper-student-map/paper-student-map.component';
import { SearchStudentsComponent } from './search-students/search-students.component';
import { ExaminerListComponent } from './examiner-list/examiner-list.component';
import { PaperAllocationComponent } from './paper-allocation/paper-allocation.component';


const routes: Routes = [
  { path: 'menu', component: MainMenuComponent, canActivate: [AuthGuardService] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'about', component: AboutComponent },
  { path: 'privileges', component: PrivilegesComponent,canActivate: [AuthGuardService] },
  { path: 'notification-template', component: NotificationTemplateComponent },
  { path: 'invite-examiner-allocation', component: InviteExaminerAllocationComponent },
  { path: 'examiner-allocation-status', component: ExaminerAllocationStatusComponent },
  { path: 'examiner-allocation', component: HodWiseDisciplineComponent },
  { path: 'paper-allocation', component: PaperAllocationComponent },
  { path: 'marks-submission-status', component: MarksSubmissionStatusComponent },
  { path: 'logged-in-user-status', component: LoggedInUserStatusComponent },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuardService] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [LoginGuardService] },
  { path: 'organization', component: OrganizationComponent, canActivate: [AuthGuardService] },
  { path: 'programs', component: ProgramComponent, canActivate: [AuthGuardService] },
  { path: 'students', component: StudentsComponent, canActivate: [AuthGuardService] },
  { path: 'staffs', component: StaffsComponent, canActivate: [AuthGuardService] },
  { path: 'student/:id', component: StudentComponent, canActivate: [AuthGuardService] },
  { path: 'papers', component: PapersComponent, canActivate: [AuthGuardService] },
  { path: 'paper/:id', component: PaperComponent, canActivate: [AuthGuardService] },
  { path: 'examiners/:id', component: ExaminersComponent, canActivate: [AuthGuardService] },
  { path: 'reviewers/:id', component: ReviewersComponent, canActivate: [AuthGuardService] },
  { path: 'sessions', component: SessionsComponent, canActivate: [AuthGuardService] },
  { path: 'exams/:id', component: ExamsComponent, canActivate: [AuthGuardService] },
  { path: 'paper-student-map', component: PaperStudentMapComponent, canActivate: [AuthGuardService] },

  { path: 'data-preview', component: DataPreviewComponent,canActivate: [AuthGuardService] },



  //pop-ups
  { path: 'blank', component: BlankComponent, outlet: 'detailSection' },
  { path: 'department/:id', component: DepartmentComponent, outlet: 'detailSection', canActivate: [AuthGuardService] },
  { path: 'student-summary/:id', component: StudentSummaryComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService] },
  { path: 'paper-summary/:id', component: PaperSummaryComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService] },
  { path: 'session-details/:id', component: SessionDetailsComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService] },
  { path: 'discipline-details/:id', component: DisciplineDetailsComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService] },
  { path: 'program-details/:id', component: ProgramDetailsComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService] },
  { path: 'organization-details/:id', component: OrganizationDetailsComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService] },
  { path: 'staff-details/:id', component: StaffDetailsComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService] },
  { path: 'search-employee', component: SearchEmployeeComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService] },
  { path: 'search-students/:id', component: SearchStudentsComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService] },
  { path: 'data-import-mapper', component: DataImportMapperComponent, outlet: 'centerPopUp', canActivate: [AuthGuardService] },

  { path: '', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'examiner-list', component: ExaminerListComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
