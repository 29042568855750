<div class="student_details_section d-flex">
    <div class="left_bg w-25 p-4">
        <h5>{{ 'Filters' | translate }} :</h5>
        <div class="row-filter mb-3">
            <input [(ngModel)]="filterKeyword" class="form-control" type="text" placeholder="Paper Name, Paper Code">
        </div>
        <div class="row-filter mb-3">
            <select [(ngModel)]="filterSession" class="form-control">
                <option value="">{{ 'Select session' | translate }}</option>
                <option *ngFor="let session of academiaSessions" [value]="session.id">{{session.name}}</option>
            </select>
        </div>
        <div class="row-filter mb-3">
            <select [(ngModel)]="filterSemester" class="form-control">
                <option value="0">{{ 'Select semester' | translate }}</option>
                <option *ngFor="let semester of [1,2,3,4,5,6,7,8]" [value]="semester">{{ 'Semester' | translate }}
                    {{semester}}</option>
            </select>
        </div>
        <div class="row-filter mb-3">
            <select [(ngModel)]="filterLevel" class="form-control">
                <option value="0">{{ 'Academic level' | translate }}</option>
                <option *ngFor="let academiclevel of env.academicLevel | keyvalue" [value]="academiclevel.key">
                    {{academiclevel.value}}</option>
            </select>
        </div>
        <div class="row-filter mb-3">
            <angular2-multiselect class="form-control" [data]="disciplines" [settings]="disciplineSearchSettings"
                [(ngModel)]="filterDiscipline">
                <c-item>
                    <ng-template let-item="item">
                        {{item.name}}
                    </ng-template>
                </c-item>
                <c-badge>
                    <ng-template let-item="item">
                        {{item.name}}
                    </ng-template>
                </c-badge>
            </angular2-multiselect>
        </div>
        <div class="text-right">
            <button (click)="paperFilter()" class="btn btn-primary font-weight-500 text-80 p-1 pr-2 pl-2">{{ 'Apply' |
                translate }} <em aria-hidden="true" class="fa fa-arrow-right"></em></button>
        </div>

    </div>
    <div class="p-4 w-75">
        <div *ngIf="copyPaper" class="form-row mb-4">
            <div class="col"><select [(ngModel)]="fromSession" class="form-control">
                    <option value="">From Session </option>
                    <option *ngFor="let session of academiaSessions" [value]="session.id">{{session.name}}</option>
                </select></div>
            <div class="col"><select [(ngModel)]="toSession" class="form-control">
                    <option value="">To Session </option>
                    <option *ngFor="let session of academiaSessions" [value]="session.id">{{session.name}}</option>
                </select></div>
            <div class="col">
                <button (click)="updateSemester()" type="button"
                    class="btn btn-primary font-weight-500 text-80">Copy</button>
            </div>
        </div>
        <!-- <div *ngIf="copyPaper" class="d-none d-flex justify-content-between">
            <div> From Session <select [(ngModel)]="fromSession" class="form-control">
                <option value="">{{ 'Select session' | translate }}</option>
                <option *ngFor="let session of academiaSessions" [value]="session.id">{{session.name}}</option>
            </select></div>
            <div>To Session <select [(ngModel)]="toSession" class="form-control">
                <option value="">{{ 'Select session' | translate }}</option>
                <option *ngFor="let session of academiaSessions" [value]="session.id">{{session.name}}</option>
            </select></div>
            <div><button (click)="updateSemester()" type="button" class="btn btn-primary btn-lg">Go</button></div>
        </div> -->
        <div class="d-flex justify-content-between">
            <!-- <h4>Papers</h4> -->
            <div class="reply_cont d-flex justify-content-end align-items-ceter">
                <div class="form-group m-0 mr-2 d-flex justify-content-between align-items-center font-weight-500">
                    <em class="fa fa-upload position-relative ml-2 p-2 rounded-circle">
                        <input type="file" (change)="uploadFile($event.target)" class="hidden-file" id="importPapers">
                    </em>
                    <label for="importPapers" class="m-0">{{ 'Import' | translate }}</label>
                </div>
                <a class="ml-2 mr-2 font-weight-500" (click)="exportCsvPaper()"><em
                        class="fa fa-download p-2 rounded-circle"></em> <span>{{ 'Export' | translate }}</span></a>
                <a class="ml-2 mr-2 font-weight-500" href="#"><em class="fa fa-unlock p-2 rounded-circle"
                        aria-hidden="true"></em> <span>{{ 'Block/Unblock' | translate }}</span></a>
                <a class="font-weight-500 mr-2" (click)="redirectTo({id:0})"><em
                        class="fa fa-plus p-2 rounded-circle"></em> <span>{{ 'Add' | translate }}</span></a>
                <a class="ml-2 font-weight-500" (click)="copy()"><em class="fa fa-copy p-2 rounded-circle"
                        aria-hidden="true"></em><span>Copy paper</span></a>
                <a class="ml-2 font-weight-500" (click)="relode()"><em class="fa fa-refresh p-2 rounded-circle"
                        aria-hidden="true"></em></a>

            </div>
        </div>

        <div class="text-80">
            <div class="p-1 pr-2 pl-2 text-100">
                <div class="row white p-2">
                    <div class="col-sm-3">
                        <p class="m-0 font-weight-500"></p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500"></p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500"></p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500"></p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center"></p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500 text-center"></p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{ 'Mid Term' | translate }}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{ 'End Term' | translate }}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500 text-center"></p>
                    </div>
                </div>
                <div class="row white p-2">
                    <div class="col-sm-2 d-flex align-items-start">
                        <!-- <input type="checkbox" class="mr-2 mt-3px"> -->
                        <p class="m-0 font-weight-500">{{ 'Name' | translate }}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500">{{ 'Group' | translate }}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500">{{ 'Codes' | translate }}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500">{{ 'Session' | translate }}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{ 'Paper Type' | translate }}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500 text-center">{{ 'Semester' | translate }}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{ 'Marks' | translate }}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{ 'Marks' | translate }}</p>
                    </div>
                    <div class="col-sm-1 d-flex align-items-start justify-content-between">
                        <p class="m-0 font-weight-500 text-center">{{ 'Credit' | translate }}</p>
                        <!-- <em class="fa fa-pencil ml-2 mt-3px"></em> -->
                    </div>
                </div>
            </div>
            <div class="p-1 pr-2 pl-2">
                <div *ngFor="let paper of papers | paginate: {id: 'papersPagination',
                                                      itemsPerPage: pageSize,
                                                      currentPage: papersCurrentPage,
                                                      totalItems: totalItemsCount}; index as i;"
                    (click)="redirectTo(paper)" class="row item-row text-80 p-2">

                    <div class="col-sm-2 d-flex align-items-start">
                        <input type="checkbox" class="mr-2 mt-3px">
                        <p class="m-0 font-weight-500">{{paper.name}}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500">{{paper.groupCode}}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500">{{paper.code}}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500">{{paper.sessionName}}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p
                            class="m-0 p-1 badge text-white font-weight-700 text-center paper_{{env.paperType[paper.paperType]}}">
                            {{env.paperType[paper.paperType]}}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500 text-center">{{paper.semester}}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{paper.midTermAllocatedMarks}}</p>
                    </div>
                    <div class="col-sm-1 p-0">
                        <p class="m-0 font-weight-500 text-center">{{paper.endTermAllocatedMarks}}</p>
                    </div>
                    <div class="col-sm-1 d-flex align-items-start justify-content-between">
                        <p class="m-0 font-weight-500 text-center">{{paper.credit}}</p>
                        <em (click)="redirectTo(paper)" class="fa fa-pencil ml-2 mt-3px"></em>
                    </div>
                </div>
            </div>
            <div class="d-flex p-0 pt-3 justify-content-between">
                <div class="font-weight-600">Total {{ totalItemsCount }} in {{ totalPageCount }} pages.</div>
                <pagination-controls id="papersPagination" (pageChange)="onPageChange($event)"></pagination-controls>
            </div>
        </div>
        <table class="d-none" id="paperData" *ngIf="paperCsvData.length>0">
            <tr>
                <td>Name</td>
                <td>Group</td>
                <td>Codes</td>
                <td>Session</td>
                <td>Paper Type</td>
                <td>Semester</td>
                <td>Mid Term Marks</td>
                <td>End Term Marks</td>
                <td>Credit</td>
            </tr>
            <tr *ngFor="let paper of paperCsvData">
                <td>{{paper.name}}</td>
                <td>{{paper.groupCode}}</td>
                <td>{{paper.code}}</td>
                <td>{{paper.sessionName}}</td>
                <td>{{env.paperType[paper.paperType]}}</td>
                <td>{{paper.semester}}</td>
                <td>{{paper.midTermAllocatedMarks}}</td>
                <td>{{paper.endTermAllocatedMarks}}</td>
                <td>{{paper.credit}}</td>
            </tr>
        </table>
    </div>
</div>