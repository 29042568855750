import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { DisciplineService } from '../api-client/discipline.service';
import { UtilityService } from '../api-client/utility.service';
import { paperService } from '../api-client/paper.service';
export class ExaminerListComponent {
    constructor(_uiService, _route, _router, _disciplineService, _utilityService, _paperService) {
        this._uiService = _uiService;
        this._route = _route;
        this._router = _router;
        this._disciplineService = _disciplineService;
        this._utilityService = _utilityService;
        this._paperService = _paperService;
        this.employees = [];
        this.examiners = [];
        this.reviewers = [];
        this.thirdExaminer = [];
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this._route.params.subscribe((params) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.employees = [];
                this.disciplineId = params.id;
                this.type = params.type;
                this.paperId = params.paperId;
                this.examId = params.examId;
                if (this.paperId && this.examId) {
                    yield this.selectExam(this.examId);
                }
                if (this.disciplineId)
                    yield this.getDisciplineWiseEmployees(this.disciplineId);
            }));
        });
    }
    getDisciplineWiseEmployees(disciplineId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var result = yield this._disciplineService.getEmployees(disciplineId).toPromise();
            yield result.forEach((value, index) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.employees.push({ item: value, checked: false, allocatedMarks: '', uploadedMidMarks: false, uploadedEndMarks: '', uploadedEndTermMarks: false });
            }));
            yield this.createData();
        });
    }
    createData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.reviewers.length > 0 && this.type == 1) {
                this.employees = this.employees.filter((gameToken) => this.reviewers.toString().includes(gameToken.item.id.toString()) == false);
            }
            if (this.type == 2 || this.type == 3) {
                this.employees = this.employees.filter((gameToken) => this.examiners.toString().includes(gameToken.item.id.toString()) == false);
            }
        });
    }
    selectExam(examId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.examiners = [];
            this.reviewers = [];
            this.thirdExaminer = [];
            if (!examId)
                return null;
            var result = yield this._paperService.getEvaluators(this.paperId, this.examId).toPromise();
            yield result.forEach((data, index) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (data.type == 2) {
                    this.examiners.push(data.examinerId);
                }
                else if (data.type == 4) {
                    this.reviewers.push(data.examinerId);
                }
                else {
                    this.thirdExaminer.push(data.examinerId);
                }
            }));
        });
    }
    assignEmployee(e, type) {
        var selectedEmp = this.employees.filter((e) => e.checked == true);
        if (type == 1) {
            this._utilityService.submittedEmpArray(selectedEmp);
        }
        if (type == 2) {
            this._utilityService.submittedReviewerArray(selectedEmp);
        }
        if (type == 104) {
            this._utilityService.submittedThirdExaminerArray(selectedEmp);
        }
    }
    onSelect() {
        this._router.navigate([
            "../",
            {
                outlets: {
                    rightMainPopup: null
                }
            }
        ], {
            relativeTo: this._route
        });
    }
}
