import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { config } from '../config';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UtilityService = /** @class */ (function () {
    function UtilityService(_http) {
        var _this = this;
        this._http = _http;
        this.onTemplatesUpdated = new Subject();
        this.getEmpList = new BehaviorSubject({ data: false });
        this.getReviewerList = new BehaviorSubject({ data: false });
        this.getThirdExaminerList = new BehaviorSubject({ data: false });
        this.isDataUpdated = new BehaviorSubject({ data: false });
        _http.get(config.apiEndPointBase + "utility/notification-templates").subscribe(function (result) {
            _this._templates = result;
        });
    }
    UtilityService.prototype.submittedEmpArray = function (listValue) {
        this.getEmpList.next(listValue);
    };
    UtilityService.prototype.submittedReviewerArray = function (listValue) {
        this.getReviewerList.next(listValue);
    };
    UtilityService.prototype.submittedThirdExaminerArray = function (listValue) {
        this.getThirdExaminerList.next(listValue);
    };
    UtilityService.prototype.updateIsDataUpdated = function (listValue) {
        this.isDataUpdated.next({ data: listValue });
    };
    UtilityService.prototype.getTemplate = function (id) {
        if (this._templates == null)
            return null;
        var items = this._templates.filter(function (x) { return x.id == id; });
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    };
    UtilityService.prototype.getTemplates = function () {
        return this._http.get(config.apiEndPointBase + "utility/notification-templates");
    };
    UtilityService.prototype.save = function (data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "utility", data, {})
                .pipe(map(function (response) {
                return response;
            }));
        } /* else {
          return this._http
            .post(config.apiEndPointBase + "utility", data, {})
            .pipe(map((response: any) => {
              return response;
            }));
        } */
    };
    UtilityService.prototype.sendNotification = function (department) {
        return this._http
            .post(config.apiEndPointBase + "action/send-examiner-allocation-request/" + department.departmentId, { departmentId: department.departmentId }, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    UtilityService.prototype.stopNotification = function (department) {
        return this._http
            .put(config.apiEndPointBase + "action/cancel-examiner-allocation-request/" + department.departmentId, { departmentId: department.departmentId }, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    UtilityService.prototype.activeExaminerDepartments = function () {
        return this._http
            .post(config.apiEndPointBase + "action/active-examiner-allocation-requests", {}, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    UtilityService.prototype.unsetEvalutors = function (data) {
        return this._http
            .put(config.apiEndPointBase + "action/unset-evaluators/" + data.examinationId + "/" + data.id, {}, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    UtilityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilityService_Factory() { return new UtilityService(i0.ɵɵinject(i1.HttpClient)); }, token: UtilityService, providedIn: "root" });
    return UtilityService;
}());
export { UtilityService };
